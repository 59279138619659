import React from 'react';
import { Container, Image, Carousel, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { UrlPaths } from './components/urlPaths';
import { Link } from 'react-router-dom';

import HeaderImage from './images/banner_pae.jpg'
import CarouselItem1 from './images/rb_building_general_construction_diesel_driven_pumps.jpg'
import CarouselItem2 from './images/rb_food_production_meat_processing_pumps.jpg'
import CarouselItem3 from './images/rb_mining_mineral_processing_pae.jpg'
import CarouselItem4 from './images/rb_pump_equipment_water_transfer.jpg'

export const PageLayout = ({ children }) => {
    return (
      <Container style={{ padding: 0, backgroundColor: 'white', marginBottom: 20, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
        <Image src={HeaderImage} responsive='true' style={{ width: '100%' }} />
        <Navbar bg="primary"  expand="lg" className="gap-3 px-3">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="flex-grow-1 justify-content-evenly">
              <Nav.Link as={Link} to={UrlPaths.home} >Home</Nav.Link>
              <Nav.Link as={Link} to={UrlPaths.aboutus} >About Us</Nav.Link>
              <Nav.Link as={Link} to={UrlPaths.customerservice}>Customer Service</Nav.Link>
              <Nav.Link as={Link} to={UrlPaths.enquiryform}>Product Enquiry</Nav.Link>
              <NavDropdown title="Spares & Repairs" id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to={UrlPaths.requestspareparts} >Request for Spare Parts</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={UrlPaths.requestrepair} >Service/Repair Request</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to={UrlPaths.technicalinfo}>Technical Info</Nav.Link>
              <Nav.Link as={Link} to={UrlPaths.contactus}>Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Carousel interval={5000}>
          <Carousel.Item>
            <img className='d-block w-100' src={CarouselItem1} alt='diesel' />
          </Carousel.Item>
          <Carousel.Item>
            <img className='d-block w-100' src={CarouselItem2} alt='pumps' />
          </Carousel.Item>
          <Carousel.Item>
            <img className='d-block w-100' src={CarouselItem3} alt='miningmineral' />
          </Carousel.Item>
          <Carousel.Item>
            <img className='d-block w-100' src={CarouselItem4} alt='equipment' /> 
          </Carousel.Item>
        </Carousel>
        <Container>
          {
            children
          }
        </Container>
        <div className='footer'>
          <h3>Pump Application Engineers Pty Ltd</h3>
          <p>Phone: <a style={{color: '#61dafb'}} href="tel:0437734000">0437 734 000</a> (Australia) - <a style={{color: '#61dafb'}} href="tel:+61437734000">+61 437 734 000</a> (International) </p>
          <p>ABN 66138876641</p>
        </div>
      </Container>
    )
  }