import React from 'react';
import { Col } from 'react-bootstrap';
import {  FormBuilder, formDataTypes } from '../components/formbuilder';
import { FormPage } from '../components/formpage';

const form = [
    { type: formDataTypes.headLabel, label: 'Industry' },
    {
        type: formDataTypes.checkbox, label: 'Industry', keyName: 'industry', boxes: [
            { label: 'Building Industry', keyName: 'buildingIndustry' },
            { label: 'Chemical Process', keyName: 'chemicalProcess' },
            { label: 'Construction', keyName: 'construction' },
            { label: 'Desalination', keyName: 'desalination' },
            { label: 'Food and Beverage', keyName: 'foodAndBeverage' },
            { label: 'Mineral Process', keyName: 'mineralProcess' },
            { label: 'Mining', keyName: 'mining' },
            { label: 'Municipal Sewage Treatment', keyName: 'municipalSewageTreatment' },
            { label: 'Oil and Gas', keyName: 'oilAndGas' },
            { label: 'Petrochemical', keyName: 'petrochemical' },

            { label: 'Potable Water Treatment', keyName: 'potableWaterTreatment' },
            { label: 'Power Generation', keyName: 'powerGeneration' },
            { label: 'Pulp and Paper', keyName: 'pulpAndPaper' },

            { label: 'Steel Production', keyName: 'steelProduction' },
            { label: 'Waste Water Treatment', keyName: 'wasteWaterTreatment' },
        ]
    },

    { type: formDataTypes.headLabel, label: 'Contact Details' },
    { type: formDataTypes.text, label: 'Company Name', keyName: 'companyName', required: true },
    { type: formDataTypes.text, label: 'Contact Name', keyName: 'contactName', required: true },
    { type: formDataTypes.text, label: 'Position', keyName: 'position' },
    { type: formDataTypes.text, label: 'Phone Number', keyName: 'phoneNumber', required: true },
    { type: formDataTypes.text, label: 'Fax Number', keyName: 'faxNumber' },
    { type: formDataTypes.text, label: 'Email Address', keyName: 'email' },
    { type: formDataTypes.text, label: 'Address', keyName: 'address' },
    { type: formDataTypes.text, label: 'State', keyName: 'state' },
    { type: formDataTypes.text, label: 'Country', keyName: 'country' },
    { type: formDataTypes.text, label: 'PostCode', keyName: 'postcode' },

    { type: formDataTypes.headLabel, label: 'Pump Type' },
    {
        type: formDataTypes.checkbox, label: 'Pump Types', keyName: 'pumpTypes', boxes: [
            { label: "Air Operated Double Diaphragm", keyName: "airOperatedDoubleDiaphragm" },
            { label: "ANSI Process Centrifugal Pumps", keyName: "aNSIProcessCentrifugalPumps" },
            { label: "API610 Process Pumps", keyName: "aPI610ProcessPumps" },
            { label: "Cantilever Type Column Centrifugal Pumps", keyName: "cantileverTypeColumnCentrifugalPumps" },
            { label: "Chemical Dosing Pumps", keyName: "chemicalDosingPumps" },
            { label: "Column Centrifugal Pumps", keyName: "columnCentrifugalPumps" },
            { label: "DIN Standard Centrifigal Pumps", keyName: "dINStandardCentrifigalPumps" },
            { label: "Double Diaphragm Process Pumps", keyName: "doubleDiaphragmProcessPumps" },
            { label: "Drum Type Pumps", keyName: "drumTypePumps" },
            { label: "Electronic Variable Speed Control Devices", keyName: "electronicVariableSpeedControlDevices" },
            { label: "Food Grade Process Pumps", keyName: "foodGradeProcessPumps" },
            { label: "Gear Pumps", keyName: "gearPumps" },
            { label: "Horizontal Split Case Pumps", keyName: "horizontalSplitCasePumps" },
            { label: "ISO 2858 Centrifugal Pumps", keyName: "iSO2858CentrifugalPumps" },
            { label: "Lamella Plate", keyName: "lamellaPlate" },
            { label: "Line Shaft Turbine Pumps", keyName: "lineShaftTurbinePumps" },
            { label: "Multistage Horizontal Centrifugal Pumps", keyName: "multistageHorizontalCentrifugalPumps" },
            { label: "Multistage Vertical Centrifugal Pumps", keyName: "multistageVerticalCentrifugalPumps" },
            { label: "Peristaltic", keyName: "peristaltic" },
            { label: "Piston Pumps", keyName: "pistonPumps" },
            { label: "Plastic Centrifugals", keyName: "plasticCentrifugals" },
            { label: "Progressive Cavity", keyName: "progressiveCavity" },
            { label: "Rotary Lobe Pumps", keyName: "rotaryLobePumps" },
            { label: "Self Priming Non Assisted Wet Prime", keyName: "selfPrimingNonAssistedWetPrime" },
            { label: "Self Priming Vacuum Assisted Dry Prime", keyName: "selfPrimingVacuumAssistedDryPrime" },
            { label: "Slurry Pumps", keyName: "slurryPumps" },
            { label: "Stainless Steel Submersible Process Pumps", keyName: "stainlessSteelSubmersibleProcessPumps" },
            { label: "Submersible Contractor Pumps", keyName: "submersibleContractorPumps" },
            { label: "Submersible Sewage Pumps", keyName: "submersibleSewagePumps" }
        ]
    },

    { type: formDataTypes.headLabel, label: 'Liquid Details' },
    { type: formDataTypes.text, label: 'Service Application', keyName: 'serviceApplication' },
    { type: formDataTypes.text, label: 'Type of Liquid to be pumped', keyName: 'typeofLiquidtobepumped' },
    { type: formDataTypes.text, label: 'Temperature of Liquid to be Pumped', keyName: 'temperatureofLiquidtobePumped' },
    { type: formDataTypes.text, label: 'Density of Liquid at Pumping Temperature', keyName: 'densityofLiquidatPumpingTemperature' },
    { type: formDataTypes.text, label: 'Viscosity of Liquid at Pumping Temperature', keyName: 'viscosityofLiquidatPumpingTemperature' },
    { type: formDataTypes.text, label: 'Kinematic', keyName: 'kinematic' },
    { type: formDataTypes.text, label: 'Dynamic', keyName: 'dynamic' },
    { type: formDataTypes.text, label: 'Vapour Pressure of Liquid at Pumping Temperature', keyName: 'vapourPressureofLiquidatPumpingTemperature' },
    { type: formDataTypes.text, label: 'pH of Liquid', keyName: 'pHofLiquid' },
    { type: formDataTypes.text, label: 'Solids Concentration as a %', keyName: 'SolidsConcentrationasapercent' },

    { type: formDataTypes.headLabel, label: 'Flow Characteristics' },
    { type: formDataTypes.text, label: 'Normal Flow Rate', keyName: 'normalFlowRate' },
    { type: formDataTypes.text, label: 'Minimum Flow Rate', keyName: 'minimumFlowRate' },
    { type: formDataTypes.text, label: 'Maximum Flow Rate', keyName: 'maximumFlowRate' },
    { type: formDataTypes.text, label: 'NPSH available at pump centreline', keyName: 'nPSHavailableatpumpcentreline' },
    { type: formDataTypes.text, label: 'Suction Head - Static Head', keyName: 'suctionHeadStaticHead' },
    { type: formDataTypes.text, label: 'Suction Head - Friction Loss Component', keyName: 'suctionHeadFrictionLossComponent' },
    { type: formDataTypes.text, label: 'Discharge Head - Static Head', keyName: 'dischargeHeadStaticHead' },
    { type: formDataTypes.text, label: 'Discharge Head - Friction Loss Component', keyName: 'dischargeHeadFrictionLossComponent' },

    { type: formDataTypes.headLabel, label: 'Prime Mover Electric Driver Details' },
    { type: formDataTypes.text, label: 'Volts', keyName: 'volts' },
    { type: formDataTypes.text, label: 'Make of Motor', keyName: 'makeofMotorElectric' },
    { type: formDataTypes.text, label: 'Pole-Electric', keyName: 'poleElectric' },
    { type: formDataTypes.text, label: 'Phase', keyName: 'phase' },
    { type: formDataTypes.text, label: 'Hz', keyName: 'hz' },
    { type: formDataTypes.text, label: 'VSD-Maximum Speed', keyName: 'vSDMaximumSpeed' },
    { type: formDataTypes.text, label: 'VSD-Minimum Speed', keyName: 'vSD-Minimum Speed' },

    { type: formDataTypes.headLabel, label: 'Prime Mover Diesel Driver Details' },
    { type: formDataTypes.text, label: 'Model of Diesel', keyName: 'modelofDiesel' },
    { type: formDataTypes.text, label: 'Make of Motor', keyName: 'makeofMotor' },
    { type: formDataTypes.text, label: 'Air or Radiator Cooled', keyName: 'airorRadiatorCooled' },
    { type: formDataTypes.text, label: 'Power Rating', keyName: 'powerRating' },
    { type: formDataTypes.text, label: 'Maximum Speed', keyName: 'maximumSpeed' },
    { type: formDataTypes.text, label: 'Minimum Speed', keyName: 'minimumSpeed' },

    { type: formDataTypes.headLabel, label: 'Other Factors' },
    { type: formDataTypes.textArea, label: 'Critical issues relating to installation', keyName: 'criticalissuesrelatingtoinstallation', as: formDataTypes.textArea, row: 4 },
    { type: formDataTypes.textArea, label: 'Method of control of system', keyName: 'methodofcontrolofsystem', as: formDataTypes.textArea, row: 4 },
    { type: formDataTypes.textArea, label: 'Protection systems required', keyName: 'protectionsystemsrequired', as: formDataTypes.textArea, row: 4 },

]

export const EnquiryForm = () => {
    return (
        <Col sm={12}  style={{paddingBottom: 30 }}>
            <h2>Pump Quote Enquiry Form</h2>
            <p>Please fill in the details below and submit form.</p>
            <FormPage formTitle='Enquiry Form' formInput={form} formComponent={<FormBuilder form={form} />} />
        </Col>
    )
}