import React from 'react';
import { Col } from 'react-bootstrap';
import { FormBuilder, formDataTypes } from '../components/formbuilder';
import { FormPage } from '../components/formpage';

const form = [
    { type: formDataTypes.headLabel, label: 'Contact Details' },
    { type: formDataTypes.text, label: 'Company Name', keyName: 'companyName', required: true },
    { type: formDataTypes.text, label: 'Contact Name', keyName: 'contactName', required: true },
    { type: formDataTypes.text, label: 'Position', keyName: 'position' },
    { type: formDataTypes.text, label: 'Phone Number', keyName: 'phoneNumber', required: true },
    { type: formDataTypes.text, label: 'Fax Number', keyName: 'faxNumber' },
    { type: formDataTypes.text, label: 'Email Address', keyName: 'email' },
    { type: formDataTypes.text, label: 'Address', keyName: 'address' },
    { type: formDataTypes.text, label: 'State', keyName: 'state' },
    { type: formDataTypes.text, label: 'Country', keyName: 'country' },
    { type: formDataTypes.text, label: 'PostCode', keyName: 'postcode' },

    { type: formDataTypes.headLabel, label: 'Service and/or Repair Details' },

    { type: formDataTypes.text, label: 'Pump Brand', keyName: 'pumpBrand' },
    { type: formDataTypes.text, label: 'Pump Type', keyName: 'pumpType' },
    { type: formDataTypes.text, label: 'Pump Model', keyName: 'pumpModel' },
    { type: formDataTypes.textArea, label: 'Issues Requiring Attention', keyName: 'issuesRequiringAttention', as: formDataTypes.textArea, row: 4 },
    { type: formDataTypes.textArea, label: 'Please enter a few details about your service and repair enquiry', keyName: 'fewdetails', as: formDataTypes.textArea, row: 4 },
]



export const ServiceRepair = () => {
    return (
        <Col sm={12}  style={{paddingBottom: 30 }}>

            <h2>Service/Repair Request</h2>
            <p>Please fill in the details below and submit form.</p>
            <FormPage formTitle='Service Repair' formInput={form} formComponent={<FormBuilder form={form} />} />
        </Col>
    )
}

